import React from 'react'

import { Base404Page } from '@product-site-frontend/shared';

import PageBox from '../components/PageBox';
import SEO from '../components/SEO';

const browser = typeof window !== "undefined" && window;

export default function NotFoundPage() {
  return (
    browser && (
      <PageBox>
        <SEO pageData={{ title: 'Страница не найдена' }} />
        <Base404Page />
      </PageBox>
    )

  )
}